import styled from "styled-components";
import ButtonGroup from "./components/ButtonGroup";
import Main from "./components/Main";
import PrintButton from "./components/PrintButton";
import Resume from "./components/Resume";

const StyledApp = styled.div`
	font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
`;

function App() {
	return (
		<StyledApp>
			<Main>
				<ButtonGroup>
					<PrintButton />
				</ButtonGroup>
				<Resume />
			</Main>
		</StyledApp>
	);
}

export default App;
