import styled from "styled-components";

const StyledSection = styled.section`
	h2 {
		color: ${(props) => props.theme.primaryColor};
		font-weight: 500;
		margin-bottom: 0.5rem;
	}
`;

export default function Section({ heading, children }) {
	return (
		<StyledSection>
			<h2>{heading}</h2>
			{children}
		</StyledSection>
	);
}
