import { useContent } from "./ContentProvider";
import Education from "./Education";
import Section from "./Section";

export default function EducationSection() {
	const {
		educationSection: { heading, items },
	} = useContent("resume");

	return (
		<Section heading={heading}>
			{items.map((education) => (
				<Education key={education.id} {...education} />
			))}
		</Section>
	);
}
