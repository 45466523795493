import styled from "styled-components";
import DatePeriod from "./DatePeriod";

const Degree = styled.div`
	display: flex;
	justify-content: space-between;
`;

export default function Education({
	school,
	degree,
	startDate,
	endDate,
	subjects,
}) {
	return (
		<div>
			<b>{school}</b>
			<ul>
				<li>
					<Degree>
						<span>{degree}</span>
						<DatePeriod startDate={startDate} endDate={endDate} />
					</Degree>
					<hr />
					<span>Courses: {subjects.join(", ")}.</span>
				</li>
			</ul>
		</div>
	);
}
