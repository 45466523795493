import styled from "styled-components";

const StyledBar = styled.span`
	color: ${(props) => props.theme.primaryColor};
	margin: 0 0.5rem;
`;

export default function Bar() {
	return <StyledBar data-type="bar">|</StyledBar>;
}
