import styled from "styled-components";
import useResumeContent from "../hooks/useResumeContent";
import EducationSection from "./EducationSection";
import Links from "./Links";
import TechnicalSkillSection from "./TechnicalSkillSection";
import WorkExperienceSection from "./WorkExperienceSection";

const Heading = styled.h1`
	text-align: center;
	color: ${(props) => props.theme.primaryColor};
	margin-bottom: 0.5rem;
`;

function Resume() {
	const { name, links } = useResumeContent();

	return (
		<>
			<header>
				<Heading>{name}</Heading>
				<Links links={links} />
			</header>
			<WorkExperienceSection />
			<EducationSection />
			<TechnicalSkillSection />
		</>
	);
}

export default Resume;
