import useResumeContent from "../hooks/useResumeContent";
import Section from "./Section";
import WorkExperience from "./WorkExperience";

export default function WorkExperienceSection() {
	const {
		workExperienceSection: { heading, items },
	} = useResumeContent();

	return (
		<Section heading={heading}>
			{items.map((workExperience) => (
				<WorkExperience key={workExperience.id} {...workExperience} />
			))}
		</Section>
	);
}
