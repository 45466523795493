import styled from "styled-components";
import Bar from "./Bar";
import Link from "./Link";

const StyledLinks = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	a {
		font-size: 0.9rem;
	}

	@media (max-width: 768px) {
		[data-type="bar"] {
			display: none;
		}
	}

	@media print {
		[data-type="bar"] {
			display: initial;
		}
	}
`;

export default function Links({ links }) {
	const items = links.reduce((acc, link, index) => {
		if (links.length === index + 1) acc.push(<Link key={index} {...link} />);
		else acc.push(<Link key={index} {...link} />, <Bar key={index + 1000} />);
		return acc;
	}, []);

	return <StyledLinks>{items}</StyledLinks>;
}
