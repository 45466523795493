import styled from "styled-components";

const StyledPrintButton = styled.button`
	cursor: pointer;
	color: ${(props) => props.theme.primaryColor};
	font-size: 0.9rem;
	margin-top: 0.3rem;

	@media print {
		display: none;
	}
`;

export default function PrintButton() {
	return (
		<StyledPrintButton type="button" onClick={window.print}>
			🖶 Print
		</StyledPrintButton>
	);
}
