import React, { useContext } from "react";

const ContentContext = React.createContext(null);

export const ContentProvider = ({ content, children }) => (
	<ContentContext.Provider value={content}>{children}</ContentContext.Provider>
);

export const useContent = (key) => {
	const content = useContext(ContentContext);
	return content[key] || content;
};
