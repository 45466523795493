import styled from "styled-components";

const StyledLink = styled.a`
	&:link,
	&:visited {
		color: ${(props) => props.theme.primaryColor};
		font-weight: 500;
		text-decoration: none;
	}
`;

export default function Link({ label, url }) {
	return (
		<StyledLink href={url} target="_blank" rel="noreferrer">
			{label}
		</StyledLink>
	);
}
