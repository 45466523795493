import styled from "styled-components";
import DatePeriod from "./DatePeriod";

const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;

export default function WorkExperience({
	company,
	position,
	startDate,
	endDate,
	description,
}) {
	return (
		<>
			<Header>
				<div>
					<strong>{company},</strong> <i>{position}</i>
				</div>
				<DatePeriod startDate={startDate} endDate={endDate} />
			</Header>
			<hr />
			<ul>
				{description.map((point, index) => (
					<li key={index}>{point}</li>
				))}
			</ul>
		</>
	);
}
