const content = {
	resume: {
		name: "Divyansh Yadav",
		links: [
			{
				label: "divyansh.yadav@gmail.com",
				url: "mailto:divyansh.yadav@gmail.com",
			},
			{
				label: "github.com/divyanshyadav",
				url: "https://github.com/divyanshyadav",
			},
			{
				label: "linkedin.com/in/divyanshyadav3",
				url: "https://www.linkedin.com/in/divyanshyadav3/",
			},
		],
		workExperienceSection: {
			heading: "Work Experience",
			items: [
				{
					id: 1,
					company: "Amazon - IMDb",
					position: "Front End Engineer",
					startDate: "11/2022",
					endDate: "Present",
					description: [
						"Created reusable components that are used to modernize all IMDb charts and list like pages.",
						"Coordinated with various teams to standardize URL query parameters for IMDb website and created a library for everyone to use.",
						"Created a scalable and reusable solution to render large list(150+ expensive items) which improved the user interaction delay by 90%.",
						"Participated in team operations(CI/CD) to maintain the owned systems once a month for a week.",
						"Worked in the agile manner to deliver products end-to-end.",
						"Maintained and improved www.boxofficemojo.com",
					],
				},
				{
					id: 2,
					company: "Wipro Digital",
					position: "Senior Software Engineer",
					startDate: "06/2019",
					endDate: "10/2022",
					description: [
						"Developed and shipped many front-end projects built from scratch for Lloyds Banking Group.",
						"Contributed and supported a front-end boilerplate, reducing setup time to start a new project by roughly 70%.",
						"Developed and contributed many reusable react components to a central repository, decreasing development time by 30%.",
						"Managed and mentored a team of 5+ front-end developers.",
						"Wrote comprehensive unit tests and routinely stress-tested performance of the project.",
						"Optimized the existing react applications, decreasing load times by roughly 40%.",
						"Completed 500+ peer code reviews aimed at encouraging best practices and scalable solutions.",
					],
				},
				{
					id: 3,
					company: "Lean Apps GmbH",
					position: "Software Associate",
					startDate: "01/2016",
					endDate: "05/2019",
					description: [
						"Developed a chatbot web application for a pharmaceutical company, answering medicines queries and reducing the customer support cost by 40-50%.",
						"Created and shipped a Point of Sale(POS) web application for retail stores and restaurants.",
						"Built a mobile app for the retail store that allows users to scan and check out items without standing in long store queues and reduced the store's checkout wait time by 30-40%.",
						"Built a command-line tool that takes in LinkedIn/Zing user profile URL and returns its JSON representation, which other backend applications have used to gather user information.",
						"Setup CI/CD for iOS apps in-house using Jenkins.",
					],
				},
			],
		},
		educationSection: {
			heading: "Education",
			items: [
				{
					id: 1,
					school: "UPES, College of Computer Science",
					degree: "Bachelor of Technology in Computer Science & Engineering",
					startDate: "04/2012",
					endDate: "05/2016",
					subjects: [
						"Data Structures",
						"Design & Analysis of Algorithms",
						"Object Oriented Programming",
						"Data Communication & Networks",
						"DBMS",
						"Operating Systems",
						"Network Security & Cryptography",
					],
				},
			],
		},
		technicalSkillSection: {
			heading: "Technical Skills",
			items: [
				{
					label: "Proficient Languages & Libraries:",
					items: ["JavaScript", "React", "Typescript", "Redux", "HTML", "CSS"],
				},
				{
					label: "Knowledgeable Languages & Libraries:",
					items: [
						"Node.js",
						"Redux-Saga",
						"React Testing Library",
						"Cypress",
						"graphQL",
						"styled-components",
						"SCSS",
						"Java",
						"Python",
						"React Native",
						"Objective C",
						"DialogFlow",
					],
				},
				{
					label: "Tools:",
					items: ["Git", "Linux", "Jest", "ESLint", "Webpack", "Jenkins"],
				},
			],
		},
	},
};

export default content;
