import styled from "styled-components";
import useResumeContent from "../hooks/useResumeContent";
import Section from "./Section";

const Wrapper = styled.section`
	li {
		list-style-type: none;
		margin-bottom: 0.5rem;
	}
`;

export default function TechnicalSkillSection() {
	const {
		technicalSkillSection: { heading, items },
	} = useResumeContent();

	return (
		<Section heading={heading}>
			<Wrapper>
				{items.map((item) => (
					<li key={item.label}>
						<b>{item.label}</b> {item.items.join(", ")}
					</li>
				))}
			</Wrapper>
		</Section>
	);
}
